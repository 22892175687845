
/**
 * Table whit all the profiles in the project
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions } from "vuex";
import { ProjectProfile } from "@/interface/projectsInterface";
import IntervalDetailsTable from "@/components/project/SingleProject/Profiles/IntervalDetailsTable.vue";
import confirmModal from "@/mixins/confirmModal/confirmModal";
import UserAccess from "@/model/User/UserAccess";
import { TranslateResult } from "vue-i18n";
import Project from "@/model/Project";

@Component({
  name: "ProfilesTable",
  components: {
    IntervalDetailsTable
  },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("ProjectProfilesStore", {
      ALL_PROJECT_PROFILES: "GetAllProfilesForCurrentProject"
    }),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapActions("ProjectProfilesStore", ["GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT", "REMOVE_PROFILE"])
  }
})
export default class MembersListTable extends Mixins(confirmModal) {
  /******Props *****/

  /******Vuex *****/
  readonly currentProject!: Project;
  readonly ALL_PROJECT_PROFILES!: Array<ProjectProfile>;
  readonly getUserAccess!: UserAccess;
  private REMOVE_PROFILE!: Function;
  private GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT!: Function;

  /******localState *****/
  labelForTheProfiles: Array<{
    key: string;
    sortable: boolean;
    label: TranslateResult;
  }> = [
    {
      key: "id",
      sortable: false,
      label: this.translateTableHeaderLabel("id")
    },
    {
      key: "name",
      sortable: false,
      label: this.translateTableHeaderLabel("name")
    },
    {
      key: "actions",
      sortable: false,
      label: this.translateTableHeaderLabel("actions")
    }
  ];

  openRows: { [key: string]: any } = {};
  /*****  Methods ******/
  removeProfile(id: number | string | undefined) {
    this.$emit("updateStatus", null);
    this.confirm(
      this.$t("projects.singleProjectView.confirmationModal.body", {
        name: ` profile # ${id}`
      }).toString(),
      async () => {
        try {
          let removeStatus = await this.REMOVE_PROFILE({
            profileId: id,
            projectId: this.$route.params.id
          });
          if (removeStatus.status === 204) {
            this.$emit("updateStatus", {
              class: "success",
              msg: this.$t("project.profiles.profilesTable.actions.deleteSuccess")
            });
          } else {
            this.$emit("updateStatus", {
              class: "danger",
              msg: this.$t("project.profiles.profilesTable.actions.deleteError")
            });
          }
        } catch (error) {
          console.log(error);
        }
      }
    );
  }

  editProfile(profileId: number | undefined | string) {
    this.$emit("updateStatus", null);
    this.$emit("getProfileById", { id: profileId, edit: true });
  }

  translateTableHeaderLabel(label: string) {
    return this.$t(`project.profiles.profileTableLabel.${label}`);
  }

  handleDeleteStatus(status: object) {
    this.$emit("updateStatus", status);
  }

  handleToggle(id: string | undefined) {
    if (`profile-${id}` in this.openRows) {
      this.openRows[`profile-${id}`] = !this.openRows[`profile-${id}`];
      this.openRows = { ...this.openRows };
    } else {
      this.openRows[`profile-${id}`] = true;
      this.openRows = { ...this.openRows };
    }
  }
}
