import { render, staticRenderFns } from "./ProjectSceneTabView.vue?vue&type=template&id=1023fbd4&scoped=true&"
import script from "./ProjectSceneTabView.vue?vue&type=script&lang=ts&"
export * from "./ProjectSceneTabView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1023fbd4",
  null
  
)

export default component.exports