
/**
 * Scenes tab view for all the scenes avialable for the project type Road Zipper
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapActions } from "vuex";
import SceneOptionList from "@/components/project/SingleProject/Scene/SceneOptionList.vue";
@Component({
  name: "ProjectSceneTabView",
  methods: {
    ...mapActions("RoadZipperStore", {
      GET_ALL_PROJECT_SCENES_IN_THE_PROJECT: "GET_ALL_SCENES_RZP_PROJECT"
    })
  },
  components: {
    SceneOptionList
  }
})
export default class ProjectSceneTabView extends Vue {
  /*---------- Vuex  ----------*/
  private GET_ALL_PROJECT_SCENES_IN_THE_PROJECT!: Function;
}
