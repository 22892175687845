
/**
 * The list of posible scenes in the system
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */
interface SceneResp {
  id: number;
  stateId: number;
}
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mapGetters } from "vuex";
import Project from "@/model/Project";

@Component({
  name: "SceneOptionList",
  computed: {
    ...mapGetters("RoadZipperStore", {
      ALL_SCENES_OPTIONS: "ALL_SCENES_OPTIONS",
      RZP_ACTIVE_SCENE: "RZPActiveScene"
    }),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject"
    })
  }
})
export default class SceneOptionList extends Vue {
  /*----------  Vuex  ----------*/
  private readonly ALL_SCENES_OPTIONS!: object;
  private readonly RZP_ACTIVE_SCENE!: SceneResp;
  private readonly currentProject!: Project;
  openRows: { [key: string]: any } = {};

  hanmdleToggle(id: string) {
    if (`scene-${id}` in this.openRows) {
      this.openRows[`scene-${id}`] = !this.openRows[`scene-${id}`];
      this.openRows = { ...this.openRows };
    } else {
      this.openRows[`scene-${id}`] = true;
      this.openRows = { ...this.openRows };
    }
    this.$root.$emit("bv::toggle::collapse", `scene-${id}`);
  }
}
