
/**
 * view edit and create projects profiles
 * Get all the Profiles avialable in the system for VSU type of projects
 *
 * @author Reflect-Media <reflect.media GmbH>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 */

import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";

import ProfilesTable from "@/components/project/SingleProject/Profiles/ProfilesTable.vue";
import ModalAddProfile from "@/components/project/SingleProject/Profiles/ModalAddProfile.vue";
import { ProjectProfile } from "@/interface/projectsInterface";
import UserAccess from "@/model/User/UserAccess";

// Pdf-report component generate a profile report
import PdfReportGeneratorModal from "@/components/project/SingleProject/Report/PdfReportGeneratorModal.vue";
import Project from "@/model/Project";

// ! Components
@Component({
  name: "ProjectProfilesTabView",
  components: { ProfilesTable, ModalAddProfile, PdfReportGeneratorModal },
  computed: {
    ...mapGetters(["getUserAccess"]),
    ...mapGetters("ProjectProfilesStore", {
      profileToEdit: "GetProfileToUpdate"
    }),
    ...mapGetters("projects", {
      currentProject: "GetCurrentProject"
    })
  },
  methods: {
    ...mapMutations("ProjectProfilesStore", ["RESET_EDIT_PROFILE"]),
    ...mapActions("ProjectProfilesStore", ["GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT", "GET_PROFILE_DETAILS"])
  }
})
export default class ProjectProfilesTabView extends Vue {
  /*****  Vuex ******/
  private GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT!: Function;
  private GET_PROFILE_DETAILS!: Function;
  private readonly getUserAccess!: UserAccess;
  private readonly currentProject!: Project;
  private readonly profileToEdit!: ProjectProfile;

  isEditModal: boolean = false;
  /*****  HelperFunction ******/
  emitUpdateStatus(status: object) {
    this.$emit("updateStatus", status);
  }

  async handleEditProfileOpen({ id, edit }: { id: number; edit: boolean }) {
    // Fetch the profile details
    let getDetails = await this.GET_PROFILE_DETAILS(id);
    if (getDetails.status === 200) {
      this.$bvModal.show("modalCreateNewProfile");
      this.isEditModal = edit;
    }
  }
  handleOpenCreateProfile() {
    this.isEditModal = false;

    this.$bvModal.show("modalCreateNewProfile");
  }
  get getReportPermission() {
    return (
      this.getUserAccess.Access.VIEW.project.report.generateProfileMatrixReport ||
      this.currentProject.switchingMatrixReport
    );
  }
}
