
/**
 * Modal to create a new profile and edit a profile
 *
 * @author Reflect-Media <ig@reflect-Media.com>
 * @version 0.0.1
 *
 * @todo [ ] Test the component
 * @todo [ ] Integration test.
 * @todo [✔] Update the typescript.
 *
 */
interface IntervalInterface {
  timeStart: string;
  timeEnd: string;
  startDate: string;
  endDate: string;
  weekdays: Array<number>;
  filename: { name: string; value: string } | null;
  active: boolean;
  cbradioEnabled: boolean;
  prewarningEnabled: boolean;
  holiday: boolean;
}

import { Component, Prop, Mixins } from "vue-property-decorator";
import { mapGetters, mapActions, mapMutations } from "vuex";
import Multiselect from "vue-multiselect";
import { ProjectProfile } from "@/interface/projectsInterface";
import confirmModal from "@/mixins/confirmModal/confirmModal";

import IntervalDetailsTable from "@/components/project/SingleProject/Profiles/IntervalDetailsTable.vue";
import { ActionMessage } from "@/model/Messages/statusMessage";

import { WEP_MOCK_URL } from "@/shared/keycloak";

@Component({
  name: "ModalAddProfile",
  components: {
    Multiselect,
    IntervalDetailsTable
  },
  computed: {
    ...mapGetters("ProjectProfilesStore", {
      newProfile: "GetProfileToUpdate"
    })
  },
  methods: {
    ...mapMutations("ProjectProfilesStore", ["RESET_EDIT_PROFILE", "RESET_SECTION_PROFILE"]),
    ...mapActions("ProjectProfilesStore", [
      "GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT",
      "CREATE_NEW_PROFILE",
      "UPDATE_PROJECT_PROFILE",
      "GET_PROFILE_DETAILS"
    ]),
    ...mapActions("projects", {
      createNewInterval: "CreateNewIntervalInSection",
      GET_INTERVAL_DETAILS: "GET_INTERVAL_DETAILS",
      DELETE_iNTERVAL: "DELETE_iNTERVAL"
    })
  }
})
export default class ModalAddProfile extends Mixins(confirmModal) {
  @Prop({
    type: Boolean,
    default: false
  })
  isEditModal!: boolean;
  WEP_MOCK_URL = WEP_MOCK_URL;
  /*----------  Vuex  ----------*/
  newProfile!: ProjectProfile;

  private GetManualOverrideSignOption!: Function;
  private CREATE_NEW_PROFILE!: Function;
  private GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT!: Function;
  private RESET_EDIT_PROFILE!: Function;
  private RESET_SECTION_PROFILE!: Function;

  private UPDATE_PROJECT_PROFILE!: Function;
  private GET_PROFILE_DETAILS!: Function;

  private GET_INTERVAL_DETAILS!: Function;
  private DELETE_iNTERVAL!: Function;

  private createNewInterval!: Function;

  currentStep: number = 1;
  newInterval: IntervalInterface = {
    timeStart: "",
    timeEnd: "",
    startDate: "",
    endDate: "",
    weekdays: [],
    filename: null,
    active: true,
    cbradioEnabled: false,
    prewarningEnabled: false,
    holiday: false
  };

  createProfileStatus: null | ActionMessage = null;

  public get checkRequired(): boolean {
    let isHoliday = this.newInterval.holiday ? true : this.newInterval.weekdays.length > 0;
    const required: null | boolean =
      this.newInterval.timeStart.length > 0 &&
      this.newInterval.timeEnd.length > 0 &&
      this.checkTimeInput() &&
      this.newInterval.filename &&
      isHoliday;
    return required ? false : true;
  }

  checkTimeInput() {
    let reg = /[0-9]{2}:[0-9]{2}/;
    return reg.test(this.newInterval.timeStart) && reg.test(this.newInterval.timeEnd);
  }

  /*----------  local data  ----------*/
  resetIntervalValue() {
    this.newInterval = {
      timeStart: "",
      timeEnd: "",
      startDate: "",
      endDate: "",
      weekdays: [],
      filename: null,
      active: true,
      cbradioEnabled: false,
      prewarningEnabled: false,
      holiday: false
    };
  }

  /*----------  Methods  ----------*/
  handleCreateNewInterval() {
    this.createProfileStatus = null;
    let formattedInterval: any = this.newInterval;
    let weekdays: Array<any> = [];
    if (!formattedInterval.holiday) {
      weekdays = this.newInterval.weekdays.map((day: any) => day.value);
    }

    let filename = this.newInterval.filename?.value;
    // create interval to the back end
    this.confirm(
      this.$t("project.section.displayInterval.confirmModals.msgCreateIntervalConfirm").toString(),
      async () => {
        formattedInterval.weekdays = weekdays;
        formattedInterval.filename = filename;
        formattedInterval.profileId = this.newProfile.id;

        let createIntervalStatus = await this.createNewInterval({
          newIntervalToCreate: formattedInterval
        });
        if (createIntervalStatus.status === 201) {
          this.createProfileStatus = {
            class: "success",
            msg: this.$t("project.section.displayInterval.confirmModals.msgCreatedSuccessfully").toString()
          };
          this.GET_PROFILE_DETAILS(this.newProfile.id);

          this.resetIntervalValue();
        } else {
          this.createProfileStatus = {
            class: "danger",
            msg: this.$t("project.section.displayInterval.confirmModals.msgCreatedError").toString()
          };
        }
      },
      () => {
        // if user cancels the action, reset all the inputs
        formattedInterval.weekdays = weekdays;
        this.newInterval = formattedInterval;
        this.resetIntervalValue();
      }
    );

    this.resetIntervalValue();
  }

  handleCancelCreateInterval() {
    this.resetIntervalValue();
  }

  // Profile
  async handleSubmitProfile() {
    this.$emit("updateStatus", null);
    this.createProfileStatus = null;

    if (this.isEditModal) {
      this.handleUpdateProfile();
    } else {
      this.handleCreateNewProfile();
    }
  }
  async handleCreateNewProfile() {
    this.newProfile.projectId = this.$route.params.id;

    let createProfile = await this.CREATE_NEW_PROFILE(this.newProfile);

    if (createProfile.status === 201) {
      this.GET_PROFILE_DETAILS(createProfile.data.id);
      this.createProfileStatus = {
        class: "success",
        msg: this.$t("project.profiles.actionsMsg.createSuccess").toString()
      };
      this.currentStep = 2;
    } else {
      this.createProfileStatus = {
        class: "danger",
        msg: this.$t("project.profiles.exception.unique_name").toString()
      };
    }
  }

  async handleUpdateProfile() {
    let updateProfile = await this.UPDATE_PROJECT_PROFILE({
      id: this.newProfile.id,
      profileToUpdate: this.newProfile
    });
    if (updateProfile.status === 200) {
      this.createProfileStatus = {
        class: "success",
        msg: this.$t("project.profiles.actionsMsg.updateSuccess").toString()
      };

      this.GET_PROFILE_DETAILS(this.newProfile.id);
      this.currentStep = 2;
    } else {
      this.createProfileStatus = {
        class: "danger",
        msg: updateProfile
      };
    }
  }

  handleModalClose() {
    this.GET_ALL_PROJECT_PROFILES_IN_THE_PROJECT(this.$route.params.id);
    this.RESET_EDIT_PROFILE();
    this.RESET_SECTION_PROFILE();
    this.currentStep = 1;
    this.createProfileStatus = null;
    this.newInterval = {
      timeStart: "",
      timeEnd: "",
      startDate: "",
      endDate: "",
      weekdays: [],
      filename: null,
      active: true,
      cbradioEnabled: false,
      prewarningEnabled: false,
      holiday: false
    };
  }

  handleDeleteStatus(status: ActionMessage) {
    this.createProfileStatus = status;
  }

  HandleAddCustomImage(customFilename: string, settings: any) {
    let filename = customFilename.replace(/.bmp/g, "");
    settings.filename = {
      name: filename,
      value: `${filename}.bmp`
    };
  }
}
